import { datadogLogs } from "@datadog/browser-logs";
import {
  track,
  setUserId,
  Revenue,
  revenue,
} from "@amplitude/analytics-browser";

export const identify = (address: string) => {
  setUserId(address);
};

export const trackEvent = (name: string, variables?: any) => {
  // console.log({ name, variables });
  datadogLogs.logger.info(name, variables);
  track(name, variables);
  if (name === "mint_completed") {
    const params: {
      amount: number;
      usd: number;
    } = variables;
    const event = new Revenue()
      .setProductId("monkey_nft")
      .setPrice(params.usd / params.amount)
      .setRevenue(params.usd)
      .setQuantity(params.amount);
    revenue(event);
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...(variables ?? {}),
    event: name,
  });
};
