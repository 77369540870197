import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Connector } from "wagmi";

interface SelectWalletProviderModalProps {
  isOpen: boolean;
  connectors: Connector<any, any>[];
  onConnect(connector: Connector): void;
  onClose(): void;
}

export const connectorInfo: {
  [key: string]: { name: string; icon: string; bgGradient: string };
} = {
  MetaMask: {
    name: "MetaMask",
    icon: "metamaskIcon.svg",
    bgGradient: "linear(to-r, #e78b3a, #e7ac3a)",
  },
  WalletConnect: {
    name: "Wallet Connect",
    icon: "walletConnect.svg",
    bgGradient: "linear(to-r, #237eff, #000aff)",
  },
  "Coinbase Wallet": {
    name: "Coinbase",
    icon: "walletlink.svg",
    bgGradient: "linear(to-r, #1554f0, #07297c)",
  },
};

const SelectWalletProviderModal = (props: SelectWalletProviderModalProps) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
      size={"sm"}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent bg="white" py={"8"} borderRadius="2xl">
        <ModalCloseButton size={"lg"} />
        <ModalBody textAlign="center" justifyContent="center" display="flex">
          <VStack>
            <Heading size={"lg"}>Connect crypto wallet</Heading>
            <Text>
              By connecting your wallet, you agree to our Terms of Service and
              Privacy Policy
            </Text>
            <VStack py={"4"} width="100%" spacing={"3"}>
              {props.connectors
                .filter((c) => c.ready)
                .map((connector) => {
                  let info = connectorInfo[connector.name];
                  if (!info) {
                    console.warn("unkown connector found: " + connector.name);
                    info = connectorInfo["MetaMask"];
                  }

                  return (
                    <Button
                      key={connector.id}
                      size="md"
                      isFullWidth
                      py={"8"}
                      isDisabled={!connector.ready}
                      onClick={() => props.onConnect(connector)}
                      rightIcon={<Image src={info.icon} />}
                      variant="solid"
                      justifyContent="space-between"
                      bgGradient={info.bgGradient}
                      borderRadius={"md"}
                      border={"none"}
                      _hover={{
                        boxShadow:
                          "0 0 1px 2px rgba(255, 255, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                      }}
                      _focus={{
                        boxShadow:
                          "0 0 1px 2px rgba(255, 255, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                      }}
                      _active={{
                        boxShadow:
                          "0 0 1px 2px rgba(255, 255, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                      }}
                      colorScheme={
                        connector.name === "MetaMask" ? "white" : "white"
                      }
                    >
                      {info.name}
                    </Button>
                  );
                })}
            </VStack>

            <Text>New to Ethereum?</Text>
            <Link
              isExternal
              textDecoration={"underline"}
              fontSize="sm"
              href="https://en.wikipedia.org/wiki/Cryptocurrency_wallet"
            >
              Learn more about crypto wallets <ExternalLinkIcon mx="2px" />
            </Link>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SelectWalletProviderModal;
