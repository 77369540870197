import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  Link,
  VStack,
} from "@chakra-ui/react";
import { ContractTransaction } from "ethers";
import { MintState } from "hooks/useMintWrite";
import { FaExclamationTriangle } from "react-icons/fa";

export type ConnectingState = "connect" | "sign" | "success" | "failure";

interface MintTransactionModalProps {
  isOpen: boolean;
  state: MintState;
  tx?: ContractTransaction;
  error?: string;
  onClose(): void;
}

const ConnectorImage = ({ icon }: { icon: string }) => {
  return <Image w={"12"} display="inline" src={icon} />;
};

const ErrorState = ({
  error,
  tx,
  onClose,
}: Pick<MintTransactionModalProps, "onClose" | "error" | "tx">) => {
  return (
    <VStack spacing={"6"}>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Icon as={FaExclamationTriangle} color="red" w={"12"} h={"12"} />
      </Box>
      <VStack>
        <Heading size={"lg"}>Transaction failed</Heading>
        <Text>{error ?? "Oops.. Something went wrong. Please retry."}</Text>
        {tx !== undefined ?? (
          <Link
            target={"_blank"}
            textDecoration="underline"
            href={`https://etherscan.io/tx/${tx!.hash}`}
          >
            Open Transaction
          </Link>
        )}
      </VStack>
      <Button
        variant={"solid"}
        colorScheme={"gray"}
        isFullWidth={true}
        onClick={onClose}
      >
        Close
      </Button>
    </VStack>
  );
};

const SuccessState = ({
  tx,
  onClose,
}: Pick<MintTransactionModalProps, "onClose" | "tx">) => {
  return (
    <VStack spacing={"6"}>
      <Box
        position={"relative"}
        w={"24"}
        h={"24"}
        justifyContent="center"
        mb={"4"}
      >
        <Box
          position={"absolute"}
          inset={"0"}
          width="100%"
          height="100%"
          border={"2px"}
          borderColor="green.300"
          rounded="full"
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w={"100%"}
          h={"100%"}
        ></Box>
      </Box>
      <VStack>
        <Heading color={"green.300"} size={"lg"}>
          Mint complete
        </Heading>
        <Text>Congratulations, tokens transferred to your wallet</Text>
        {tx !== undefined && (
          <Link
            target={"_blank"}
            textDecoration="underline"
            href={`https://etherscan.io/tx/${tx!.hash}`}
          >
            Open Transaction
          </Link>
        )}
      </VStack>
      <Button
        variant={"solid"}
        colorScheme={"gray"}
        isFullWidth={true}
        onClick={onClose}
      >
        Close
      </Button>
    </VStack>
  );
};

const LoadingState = ({ tx }: Pick<MintTransactionModalProps, "tx">) => {
  const title =
    tx !== undefined
      ? "Waiting for confirmation ..."
      : "Waiting for approval ...";
  const text =
    tx !== undefined
      ? "Transaction has been initiated. Waiting for confirmation."
      : "Transaction has been requested. Approve the transaction in your Wallet.";

  return (
    <VStack>
      <Box
        position={"relative"}
        w={"24"}
        h={"24"}
        justifyContent="center"
        mb={"4"}
      >
        <Spinner
          position={"absolute"}
          inset={"0"}
          width="100%"
          height="100%"
          speed="0.8s"
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w={"100%"}
          h={"100%"}
        ></Box>
      </Box>

      <Heading size={"lg"}>{title}</Heading>
      <Text>{text}</Text>
      {tx !== undefined && (
        <Link
          target={"_blank"}
          textDecoration="underline"
          href={`https://etherscan.io/tx/${tx!.hash}`}
        >
          Open Transaction
        </Link>
      )}
    </VStack>
  );
};

const MintTransactionModal = ({
  isOpen,
  state,
  tx,
  error,
  onClose,
}: MintTransactionModalProps) => {
  const connectStateChild = {
    [MintState.Initial]: () => <LoadingState tx={tx} />,
    [MintState.Loading]: () => <LoadingState tx={tx} />,
    [MintState.Success]: () => <SuccessState tx={tx} onClose={onClose} />,
    [MintState.Error]: () => (
      <ErrorState tx={tx} error={error} onClose={onClose} />
    ),
  };

  const withCloseBtn = state === MintState.Success || state === MintState.Error;

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"sm"}
    >
      <ModalOverlay />
      <ModalContent bg="white" py={"8"} borderRadius="2xl">
        {withCloseBtn && <ModalCloseButton size={"lg"} />}
        <ModalBody textAlign="center" justifyContent="center" display="flex">
          {connectStateChild[state]()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MintTransactionModal;
