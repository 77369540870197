import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { formatEther } from "@ethersproject/units";
import { BigNumber } from "ethers";
import Counter from "./counter";
import MintInputControls from "./mint_input_controls";

interface MintCheckoutV1Props {
  price: BigNumber;
  strikePrice: BigNumber;
  maxMintLimit: number;
  isLoading?: boolean;
  isConnected?: boolean;
  onConnect(): void;
  onMint(amount: number): void;
}

const MintCheckoutV1 = ({
  price,
  strikePrice,
  maxMintLimit,
  isLoading,
  isConnected,
  onConnect,
  onMint,
}: MintCheckoutV1Props) => {
  return (
    <Container maxW="container.lg">
      <VStack spacing={"4"} mb="4" bg="white">
        <Heading
          fontSize={{ base: "5xl", md: "6xl" }}
          textTransform="uppercase"
          textAlign="center"
          fontWeight="400"
        >
          Public{" "}
          <Text as="span" color="red">
            mint
          </Text>{" "}
          is live
        </Heading>
        <Text
          textAlign={"center"}
          fontSize={{ base: "md", md: "lg" }}
          lineHeight="1.5"
          maxWidth={{ base: "100%", md: "80%" }}
        >
          <b>NoEvilMonkeys</b> is a collection of 9999 randomly generated NFTs
          on the Ethereum blockchain.
        </Text>
      </VStack>
      <Stack
        direction={"column"}
        bg="gray.50"
        borderRadius={"xl"}
        px="6"
        py="4"
        maxWidth={"md"}
        alignItems={"center"}
        mx="auto"
      >
        <VStack spacing={0}>
          <Text
            textTransform={"uppercase"}
            textAlign="center"
            fontSize={{ base: "xl", md: "2xl" }}
            fontWeight="bold"
            lineHeight={"1"}
          >
            LIMITED SALE FOR Ξ{formatEther(price)}
          </Text>

          <Counter />
        </VStack>
        {isConnected ? (
          <MintInputControls
            price={price}
            maxMintLimit={maxMintLimit}
            isLoading={isLoading}
            onMint={onMint}
          />
        ) : (
          <Box pt="4" pb="2" width={"100%"}>
            <Button
              aria-label="Connect Wallet"
              isFullWidth={true}
              fontSize="xl"
              py={"6"}
              onClick={onConnect}
              colorScheme="red"
              isLoading={isLoading}
            >
              Mint now
            </Button>
          </Box>
        )}
      </Stack>
      <VStack spacing={"2"} my="4">
        <Text
          textAlign={"center"}
          fontSize={{ base: "md", md: "lg" }}
          maxWidth={{ base: "100%", md: "80%" }}
          lineHeight="1.5"
        >
          <b>We are the Good Vibe Family</b>. Surround yourself with open minded
          NFT lovers – The monkeys are <b>calling for more love</b> in our
          society and appreciation of planet Earth.
          <br />
          <b>Join and become part of our family</b>
        </Text>
      </VStack>
    </Container>
  );
};

export default MintCheckoutV1;
