import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";

interface WrongNetworkModalProps {
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  onSwitchNetwork?: () => void;
}

const WrongNetworkModal = ({
  isOpen,
  loading,
  onSwitchNetwork,
  onClose,
}: WrongNetworkModalProps) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"sm"}
    >
      <ModalOverlay />
      <ModalContent bg="white" py={"8"} borderRadius="2xl">
        <ModalCloseButton size={"lg"} />
        <ModalBody textAlign="center" justifyContent="center" display="flex">
          <VStack spacing={"6"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Icon as={FaExclamationTriangle} color="red" w={"12"} h={"12"} />
            </Box>
            <VStack>
              <Heading size={"md"}>Wrong network</Heading>
              <Text>
                Please change your network to the Ethereum Mainnet to connect.
              </Text>
            </VStack>
            {onSwitchNetwork ? (
              <Button
                variant={"solid"}
                colorScheme="gray"
                isFullWidth={true}
                isLoading={loading}
                onClick={onSwitchNetwork}
              >
                Switch to Mainnet
              </Button>
            ) : (
              <Alert status="error" textAlign={"left"}>
                <AlertIcon />
                <AlertDescription>
                  Open your wallet and change your network to Mainnet
                </AlertDescription>
              </Alert>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WrongNetworkModal;
