import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useTimeout,
  VStack,
} from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import { connectorInfo } from "./select_wallet_provider_modal";

export type ConnectingState = "connect" | "sign" | "success" | "failure";

interface ConnectingProviderModalProps {
  isOpen: boolean;
  state: ConnectingState;
  connectorName?: string;
  onClose(): void;
}

const ConnectorImage = ({ icon }: { icon: string }) => {
  return <Image w={"12"} display="inline" src={icon} />;
};

const ErrorState = ({
  onClose,
}: Pick<ConnectingProviderModalProps, "onClose">) => {
  return (
    <VStack spacing={"6"}>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Icon as={FaExclamationTriangle} color="red" w={"12"} h={"12"} />
      </Box>
      <VStack>
        <Heading size={"lg"}>Connection unsuccessful</Heading>
        <Text>
          Please authorize this website to access your Ethereum account.
        </Text>
      </VStack>
      <Button
        variant={"solid"}
        colorScheme={"gray"}
        isFullWidth={true}
        onClick={onClose}
      >
        Close
      </Button>
    </VStack>
  );
};

const SuccessState = ({
  icon,
  onClose,
}: Pick<ConnectingProviderModalProps, "onClose"> & {
  icon: string;
}) => {
  useTimeout(onClose, 1000);

  return (
    <VStack spacing={"6"}>
      <Box
        position={"relative"}
        w={"24"}
        h={"24"}
        justifyContent="center"
        mb={"4"}
      >
        <Box
          position={"absolute"}
          inset={"0"}
          width="100%"
          height="100%"
          border={"2px"}
          borderColor="green.300"
          rounded="full"
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w={"100%"}
          h={"100%"}
        >
          <ConnectorImage icon={icon} />
        </Box>
      </Box>
      <VStack>
        <Heading color={"green.300"} size={"lg"}>
          Connected
        </Heading>
      </VStack>
    </VStack>
  );
};

const ConnectSignState = ({
  waitForSign,
  name,
  icon,
}: {
  waitForSign: boolean;
  name: string;
  icon: string;
}) => {
  const text = waitForSign
    ? "Signature Request has been sent. Please sign and prove the ownership of this address."
    : "Accept the request to connect your wallet";

  return (
    <VStack>
      <Box
        position={"relative"}
        w={"24"}
        h={"24"}
        justifyContent="center"
        mb={"4"}
      >
        <Spinner
          position={"absolute"}
          inset={"0"}
          width="100%"
          height="100%"
          speed="0.8s"
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w={"100%"}
          h={"100%"}
        >
          <ConnectorImage icon={icon} />
        </Box>
      </Box>

      <Heading size={"lg"}>
        Waiting for {name}
        ...
      </Heading>
      <Text>{text}</Text>
      <Link
        textDecoration={"underline"}
        fontSize="sm"
        isExternal
        href="https://metamask.zendesk.com/hc/en-us"
      >
        Help <ExternalLinkIcon mx="2px" />
      </Link>
    </VStack>
  );
};

const ConnectingProviderModal = ({
  isOpen,
  state,
  connectorName,
  onClose,
}: ConnectingProviderModalProps) => {
  let info = connectorInfo[connectorName ?? "MetaMask"];
  if (!info) {
    console.warn("unkown connector found: " + connectorName);
    info = connectorInfo["MetaMask"];
  }

  const connectStateChild = {
    connect: () => (
      <ConnectSignState waitForSign={false} icon={info.icon} name={info.name} />
    ),
    sign: () => (
      <ConnectSignState waitForSign={true} icon={info.icon} name={info.name} />
    ),
    success: () => <SuccessState icon={info.icon} onClose={onClose} />,
    failure: () => <ErrorState onClose={onClose} />,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"sm"}>
      <ModalOverlay />
      <ModalContent bg="white" py={"8"} borderRadius="2xl">
        <ModalCloseButton size={"lg"} />
        <ModalBody textAlign="center" justifyContent="center" display="flex">
          {connectStateChild[state]()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConnectingProviderModal;
