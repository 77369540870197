import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

const LandingSections = () => {
  return (
    <>
      <Container maxW="container.lg">
        <Stack direction={{ base: "column", md: "row" }} spacing="4">
          <VStack flex={1} alignItems={{ base: "center", md: "start" }}>
            <Heading
              fontSize={{ base: "6xl", md: "9xl" }}
              textTransform={"uppercase"}
              fontWeight="400"
              color={"gray.800"}
            >
              Mission
            </Heading>
            <Heading
              fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
              textTransform={"uppercase"}
              fontWeight="400"
              color={"gray.800"}
              textAlign={{ base: "center", md: "start" }}
            >
              Our goal is to combine profile pictures with a Positive message.
            </Heading>
          </VStack>
          <Flex flex={1} justifyContent={"center"} alignItems="center">
            <Image height={"320px"} src="pfp_800x800.png" />
          </Flex>
        </Stack>
      </Container>
      <Container maxW="container.lg" py={{ base: "10", md: "20" }}>
        <Stack direction={{ base: "column", md: "row" }} spacing="4">
          <VStack
            flex={1}
            alignItems={{ base: "center", md: "start" }}
            spacing="4"
          >
            <Heading
              fontSize={{ base: "3xl", lg: "4xl" }}
              textTransform={"uppercase"}
              fontWeight="400"
              color={"gray.800"}
            >
              Community is everything
            </Heading>
            <Text
              color={"gray.800"}
              fontSize={{ base: "md", md: "lg" }}
              textAlign={{ base: "center", md: "start" }}
            >
              We will only achieve these goals with a great community where
              people want to make a difference. Let the monkeys cause a stir and
              make people think.
            </Text>
          </VStack>
          <VStack
            flex={1}
            alignItems={{ base: "center", md: "start" }}
            spacing="4"
          >
            <Heading
              fontSize={{ base: "3xl", lg: "4xl" }}
              textTransform={"uppercase"}
              fontWeight="400"
              color={"gray.700"}
            >
              The Project
            </Heading>
            <Text
              color={"gray.700"}
              fontSize={{ base: "md", md: "lg" }}
              textAlign={{ base: "center", md: "start" }}
            >
              The team was founded by two brothers (AMA & Waalo) with
              Afro-German roots, born and raised in Berlin, Germany. Their
              strong connection to Senegal has always made them aware of the
              great contradictions and injustices of our world.
            </Text>
            <Text
              color={"gray.700"}
              fontSize={{ base: "md", md: "lg" }}
              textAlign={{ base: "center", md: "start" }}
            >
              They see the opportunity to use NFTs to spread the positive
              message of their art as a perfect way to communicate.
            </Text>
            <Text
              color={"gray.700"}
              fontSize={{ base: "md", md: "lg" }}
              textAlign={{ base: "center", md: "start" }}
            >
              Together with longtime friends and their large network, they have
              built a team of people who share the same values and want to make
              a difference.
            </Text>
          </VStack>
        </Stack>
      </Container>
      <Box w={"100%"} bg="#FFFD87">
        <Container maxW="container.lg" py={{ base: "10", md: "20" }}>
          <VStack alignItems="center" spacing={{ base: "5", md: "10" }}>
            <VStack spacing="0" alignItems="center">
              <Heading
                fontSize={{ base: "8xl", lg: "9xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"gray.800"}
                textAlign="center"
                lineHeight={"0.8"}
              >
                SEE
              </Heading>
              <Heading
                fontSize={{ base: "3xl", lg: "4xl" }}
                fontWeight="400"
                color={"gray.800"}
              >
                No evil
              </Heading>
            </VStack>
            <VStack spacing="0" alignItems="center">
              <Heading
                fontSize={{ base: "8xl", lg: "9xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"gray.800"}
                textAlign="center"
                lineHeight={"0.8"}
              >
                Speak
              </Heading>
              <Heading
                fontSize={{ base: "3xl", lg: "4xl" }}
                fontWeight="400"
                color={"gray.800"}
              >
                No evil
              </Heading>
            </VStack>
            <VStack spacing="0" alignItems="center">
              <Heading
                fontSize={{ base: "8xl", lg: "9xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"gray.800"}
                textAlign="center"
                lineHeight={"0.8"}
              >
                Hear
              </Heading>
              <Heading
                fontSize={{ base: "3xl", lg: "4xl" }}
                fontWeight="400"
                color={"gray.800"}
              >
                No evil
              </Heading>
            </VStack>
          </VStack>
        </Container>
      </Box>
      <Container maxW="container.lg" py={{ base: "10", md: "20" }}>
        <VStack
          alignItems={{ base: "center", md: "start" }}
          spacing={{ base: "5", md: "6" }}
        >
          <Heading
            fontSize={{ base: "6xl", md: "9xl" }}
            textTransform={"uppercase"}
            fontWeight="400"
            color={"gray.800"}
            textAlign="center"
            lineHeight={"0.8"}
          >
            Collection
          </Heading>
          <Heading
            fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
            textTransform={"uppercase"}
            fontWeight="400"
            color={"gray.800"}
            textAlign={{ base: "center", md: "start" }}
          >
            NoEvilMonkeys is a collection of 10K randomly generated NFTs on the
            Ethereum blockchain.
          </Heading>
          <Heading
            fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
            textTransform={"uppercase"}
            fontWeight="400"
            color={"gray.800"}
            textAlign={{ base: "center", md: "start" }}
          >
            Each artwork represents one of the world famous three monkey
            characters.
          </Heading>
        </VStack>
      </Container>
      <Box w={"100%"}>
        <Image src="roadmap_divider.png" />
      </Box>
      {/* <Container maxW="container.lg" py={{ base: "10", md: "20" }}>
        <VStack spacing={"10"}>
          <Heading
            fontSize={{ base: "6xl", md: "9xl" }}
            textTransform={"uppercase"}
            fontWeight="400"
            color={"gray.800"}
            textAlign="center"
            lineHeight={"0.8"}
          >
            Roadmap
          </Heading>
          <Stack direction={{ base: "column", md: "row" }} spacing="4">
            <VStack
              flex={1}
              alignItems={{ base: "center", md: "start" }}
              spacing="4"
            >
              <Heading
                fontSize={{ base: "3xl", lg: "4xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"red"}
              >
                Community
              </Heading>
              <Heading
                fontSize={{ base: "2xl", md: "3xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"gray.800"}
                textAlign={{ base: "center", md: "start" }}
              >
                Our community is everything!
              </Heading>
              <Heading
                fontSize={{ base: "2xl", md: "3xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"gray.800"}
                textAlign={{ base: "center", md: "start" }}
              >
                We are enthusiastic NFT lovers who want to break new ground.
              </Heading>
              <Text
                color={"gray.800"}
                fontSize={{ base: "md", md: "lg" }}
                textAlign={{ base: "center", md: "start" }}
              >
                We want to thank our community for their support and will create
                many benefits.
              </Text>
              <Text
                color={"gray.800"}
                fontSize={{ base: "md", md: "lg" }}
                textAlign={{ base: "center", md: "start" }}
              >
                Every holder of a NoEvilMonkey profile picture will benefit from
                numerous promotions in the near future.
              </Text>
            </VStack>
            <VStack
              flex={1}
              alignItems={{ base: "center", md: "start" }}
              spacing="4"
            >
              <Heading
                fontSize={{ base: "3xl", lg: "4xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"red"}
              >
                Charity
              </Heading>
              <Heading
                fontSize={{ base: "2xl", md: "3xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"gray.800"}
                textAlign={{ base: "center", md: "start" }}
              >
                The main focus of this collection is education!
              </Heading>
              <Heading
                fontSize={{ base: "2xl", md: "3xl" }}
                textTransform={"uppercase"}
                fontWeight="400"
                color={"gray.800"}
                textAlign={{ base: "center", md: "start" }}
              >
                We want to support projects that work to make the world a better
                place.
              </Heading>
              <Text
                color={"gray.700"}
                fontSize={{ base: "md", md: "lg" }}
                textAlign={{ base: "center", md: "start" }}
              >
                We believe education is the key to a better future.
              </Text>
              <Text
                color={"gray.700"}
                fontSize={{ base: "md", md: "lg" }}
                textAlign={{ base: "center", md: "start" }}
              >
                Our goal is to give children access to education and thus set
                the foundation for a better life.
              </Text>
              <Text
                color={"gray.700"}
                fontSize={{ base: "md", md: "lg" }}
                textAlign={{ base: "center", md: "start" }}
              >
                In the long run, we see education as a requirement to
                successfully fight issues such as poverty, inequality and
                climate change.
              </Text>
              <Text
                color={"gray.700"}
                fontSize={{ base: "md", md: "lg" }}
                textAlign={{ base: "center", md: "start" }}
              >
                We want to generate funds to support great and dedicated
                charities.
              </Text>
            </VStack>
          </Stack>
        </VStack>
      </Container> */}
      <Box w={"100%"} bg="gray.900">
        <Container maxW="container.lg" py={{ base: "10", md: "20" }}>
          <Heading
            fontSize={{ base: "6xl", lg: "8xl" }}
            textTransform={"uppercase"}
            fontWeight="400"
            color={"white"}
            textAlign="center"
          >
            a cute and lovely art project for the good vibe!
          </Heading>
        </Container>
      </Box>
    </>
  );
};

export default LandingSections;
