import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { NextSeo } from "next-seo";
import ConnectingProviderModal, {
  ConnectingState,
} from "components/connecting_provider_modal";
import SelectWalletProviderModal from "components/select_wallet_provider_modal";
import { identify, trackEvent } from "lib/events";
import { useCallback, useEffect, useState } from "react";
import {
  chain,
  Connector,
  ConnectorAlreadyConnectedError,
  useAccount,
  useConnect,
  useNetwork,
  UserRejectedRequestError,
} from "wagmi";
import * as Sentry from "@sentry/nextjs";
import WrongNetworkModal from "components/wrong_network_modal";
import MintTransactionModal from "components/mint_transaction_modal";
import useMintRead from "hooks/useMintRead";
import useMintWrite, { MintState } from "hooks/useMintWrite";
import { DiscordIcon, TwitterIcon } from "components/nav-icons";
import MintCheckoutV1 from "components/mint_checkout_v1";
import MintCheckoutV2 from "components/mint_checkout_v2";
import LandingSections from "components/landing_sections";

const Home = () => {
  const [{ data: accountData }, disconnect] = useAccount();

  const [{ data: connectData }, connect] = useConnect();

  const [{ data: networkData, loading: networkLoading }, switchNetwork] =
    useNetwork();

  const mintInfo = useMintRead();
  const {
    state: mintState,
    tx: mintTx,
    error: mintError,
    mint,
  } = useMintWrite();

  // modal state for provider selection
  const {
    isOpen: isSelectProviderOpen,
    onOpen: onOpenSelectProvider,
    onClose: onCloseSelectProvider,
  } = useDisclosure();

  // modal state for wrong network
  const {
    isOpen: isWrongNetworOpen,
    onOpen: onOpenWrongNetwork,
    onClose: onCloseWrongNetwork,
  } = useDisclosure();

  // modal state for connecting
  const [selectedProvider, setSelectedProvider] = useState<
    string | undefined
  >();
  const [connectingState, setConnectingState] =
    useState<ConnectingState>("connect");
  const {
    isOpen: isConnectingProviderOpen,
    onOpen: onOpenConnectingProvider,
    onClose: onCloseConnectingProvider,
  } = useDisclosure();

  // modal state for mint transaction
  const {
    isOpen: isMintTransactionModalOpen,
    onOpen: onOpenMintTransactionModal,
    onClose: onCloseMintTransactionModal,
  } = useDisclosure();

  useEffect(() => {
    trackEvent("page_view");
  }, []);

  useEffect(() => {
    if (accountData?.address) {
      identify(accountData.address);
    }
  }, [accountData?.address]);

  const price = mintInfo.price;
  const strikePrice = mintInfo.strikePrice;
  const maxMintLimit = mintInfo.maxPerMint;
  const connected =
    accountData && networkData && networkData.chain?.id === chain.mainnet.id;

  const onMint = useCallback(
    async (amount: number) => {
      const chainId = await accountData!.connector!.getChainId();
      trackEvent("mint", {
        sale: "public",
        account: accountData!.address,
        amount: amount,
        chainId: chainId,
        usd: amount * 250,
      });
      onOpenMintTransactionModal();
      mint({
        price,
        amount: amount,
        isPresale: false,
        address: accountData!.address,
        chainId: chainId,
      });
    },
    [accountData, mint]
  );

  const onConnectWallet = async () => {
    trackEvent("select_wallet_provider");
    onOpenSelectProvider();
  };

  const onConnect = async (connector: Connector) => {
    Sentry.setTag("connector", connect.name);
    setSelectedProvider(connector.name);

    // close the provider selection
    onCloseSelectProvider();

    // open the connecting modal and set initial state
    setConnectingState("connect");
    onOpenConnectingProvider();

    let account: string;
    let chainId: number;
    try {
      trackEvent("connect", { type: "wallet", connector: connector.name });
      const result = await connect(connector);
      if (!result.data) {
        if (result.error instanceof UserRejectedRequestError) {
          trackEvent("connect_rejected", {
            type: "wallet",
            connector: connector.name,
          });
          setConnectingState("failure");
          return;
        } else if (result.error instanceof ConnectorAlreadyConnectedError) {
          account = await connector.getAccount();
          chainId = await connector.getChainId();
        } else {
          trackEvent("connect_failed", {
            type: "wallet",
            connector: connector.name,
          });
          Sentry.captureException(result.error);
          console.error(result.error);
          setConnectingState("failure");
          return;
        }
      } else {
        account = result.data.account!;
        chainId = result.data.chain!.id;
      }
    } catch (e) {
      if (e instanceof ConnectorAlreadyConnectedError) {
        account = await connector.getAccount();
        chainId = await connector.getChainId();
      } else {
        setConnectingState("failure");
        if (!(e instanceof UserRejectedRequestError)) {
          console.error(e);
          trackEvent("connect_failed", {
            type: "wallet",
            connector: connector.name,
          });
          Sentry.captureException(e);
        } else {
          trackEvent("connect_rejected", {
            type: "wallet",
            connector: connector.name,
          });
        }
        return;
      }
    }

    Sentry.setTag("account", account);
    Sentry.setTag("chainId", chainId);
    identify(account);

    trackEvent("connected", {
      type: "wallet",
      connector: connector.name,
      account,
      chainId,
    });

    if (chainId === chain.mainnet.id) {
      setConnectingState("success");
    } else {
      onCloseConnectingProvider();
      onOpenWrongNetwork();
    }
  };

  const onSwitchNetwork = async () => {
    trackEvent("switch_network");
    try {
      const res = await switchNetwork!(chain.mainnet.id);
      if (res.error) {
        trackEvent("switch_network_failed");
        console.error(res.error);
      } else {
        trackEvent("switch_network_success");
        onCloseWrongNetwork();
        setConnectingState("success");
        onOpenConnectingProvider();
      }
    } catch (e) {
      console.error(e);
      trackEvent("switch_network_failed");
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <NextSeo title="NoEvilMonkey Mint" />

      <Flex
        minHeight={"100vh"}
        direction="column"
        alignItems={"center"}
        justifyContent="start"
        width={"100%"}
        position="relative"
      >
        <Box
          bg="rgba(0, 0, 0, 0.2)"
          position={"fixed"}
          top="0"
          left={"0"}
          right={"0"}
        >
          <Container maxW="container.lg">
            <Flex
              py={"2"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <a href="https://seenoevilmonkeyart.com/">
                <Image height={"12"} src="logo.png" />
              </a>
              <Flex alignItems={"center"}>
                <HStack>
                  <IconButton
                    as="a"
                    aria-label="Discord"
                    href="https://discord.gg/noevilmonkey"
                    target={"_blank"}
                    colorScheme="blackAlpha"
                    variant={"ghost"}
                    icon={<DiscordIcon />}
                  />
                  <IconButton
                    as="a"
                    aria-label="Twitter"
                    href="https://twitter.com/NoEvilMonkeys_"
                    target={"_blank"}
                    colorScheme="blackAlpha"
                    variant={"ghost"}
                    icon={<TwitterIcon />}
                  />
                </HStack>
              </Flex>
            </Flex>
          </Container>
        </Box>
        <Flex
          flex={1}
          w={"100%"}
          bg="#FFFD87"
          alignItems={"end"}
          justifyItems="end"
          maxHeight={"500px"}
        >
          <Container maxW="container.lg">
            <Image src="banner.png" align={"center"} />
          </Container>
        </Flex>
        <Flex flex={1} w={"100%"} alignItems={"center"}>
          <MintCheckoutV1
            price={price}
            strikePrice={strikePrice}
            maxMintLimit={maxMintLimit}
            isLoading={mintState === MintState.Loading}
            isConnected={connected}
            onConnect={onConnectWallet}
            onMint={onMint}
          />
          {/* <MintCheckoutV2
            price={price}
            isLoading={mintState === MintState.Loading}
            isConnected={connected}
            onConnect={onConnectWallet}
            onMint={onMint}
          /> */}
        </Flex>
      </Flex>
      <LandingSections />

      <SelectWalletProviderModal
        isOpen={isSelectProviderOpen}
        connectors={connectData.connectors}
        onConnect={onConnect}
        onClose={onCloseSelectProvider}
      />
      <ConnectingProviderModal
        isOpen={isConnectingProviderOpen}
        state={connectingState}
        connectorName={selectedProvider}
        onClose={onCloseConnectingProvider}
      />
      <WrongNetworkModal
        isOpen={isWrongNetworOpen}
        loading={networkLoading ?? false}
        onSwitchNetwork={
          switchNetwork !== undefined ? onSwitchNetwork : undefined
        }
        onClose={onCloseWrongNetwork}
      />
      <MintTransactionModal
        isOpen={isMintTransactionModalOpen}
        state={mintState}
        tx={mintTx}
        error={mintError}
        onClose={onCloseMintTransactionModal}
      />
    </>
  );
};

export default Home;
