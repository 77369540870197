import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { formatEther } from "@ethersproject/units";
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";

interface MintInputControlsProps {
  price: BigNumber;
  maxMintLimit: number;
  isLoading?: boolean;
  onMint(amount: number): void;
}

const MintInputControls = ({
  price,
  maxMintLimit,
  isLoading,
  onMint,
}: MintInputControlsProps) => {
  const [mintAmount, setMintAmount] = useState<number>(1);
  const [mintAmountInput, setMintAmountInput] = useState<string>("1");

  useEffect(() => {
    setMintAmountInput(mintAmount.toString());
  }, [mintAmount]);

  const clampMintValue = (value: number): number =>
    Math.min(Math.max(value, 1), maxMintLimit);

  const setMintAmountToMax = () => {
    setMintAmount(maxMintLimit);
  };

  const decrementMintAmount = () => {
    setMintAmount(clampMintValue(mintAmount - 1));
  };

  const incrementMintAmount = () => {
    setMintAmount(clampMintValue(mintAmount + 1));
  };

  const updateMintAmount = (value: any) => {
    if (value.target.value !== "") {
      setMintAmount(clampMintValue(value.target.value));
    } else {
      // handle on blur
      setMintAmountInput("");
    }
  };

  const onMintInputBlur = (value: any) => {
    setMintAmount(clampMintValue(value.target.value));
    setMintAmountInput(clampMintValue(value.target.value).toString());
  };

  return (
    <>
      <VStack>
        <Text fontSize={{ base: "sm", md: "md" }}>
          Select the amount to mint. Max: {maxMintLimit} per txn
        </Text>
        <Stack direction={"row"} align="center" spacing={4}>
          <Stack direction={"row"} align="center" spacing={2}>
            <IconButton
              aria-label="Decrement"
              onClick={decrementMintAmount}
              icon={<MinusIcon />}
            />
            <Input
              value={mintAmountInput}
              maxWidth="14"
              type="number"
              onChange={updateMintAmount}
              onBlur={onMintInputBlur}
              fontSize="sm"
            />
            <IconButton
              aria-label="Increment"
              onClick={incrementMintAmount}
              icon={<AddIcon />}
            />
          </Stack>
          <Button
            size="md"
            px="8"
            onClick={setMintAmountToMax}
            aria-label="Max"
          >
            Max
          </Button>
        </Stack>
      </VStack>
      <Box pt="4" pb="2" width={"100%"}>
        <Button
          aria-label="Mint"
          isFullWidth={true}
          fontSize="xl"
          py={"6"}
          onClick={() => onMint(mintAmount)}
          colorScheme="red"
          isLoading={isLoading}
        >
          MINT
        </Button>
      </Box>
      <Text textAlign={"center"} fontSize={{ base: "sm", md: "md" }}>
        Price: Ξ{formatEther(price.mul(mintAmount))} + gas fee
      </Text>
    </>
  );
};

export default MintInputControls;
