import { Text } from "@chakra-ui/react";
import { useEffect } from "react";
import useLocalStorageState from "use-local-storage-state";

const endCounter = 1364;
const collectionSize = 2500;

const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

const Counter = () => {
  const [count, setCount] = useLocalStorageState("mc", {
    ssr: true,
    defaultValue: randomInteger(1214, 1250),
  });
  const add = () => {
    if (count < endCounter) {
      setCount((state) => (state < endCounter ? state + 1 : state));
    } else if (count > endCounter) {
      setCount(endCounter);
    }
  };
  useEffect(() => {
    function DoCount() {
      const randomTime = randomInteger(150, 2000);
      setTimeout(async () => {
        const random = Math.random();
        let rn = 0;
        if (random > 0.5) {
          if (random <= 0.7) {
            //2-4
            rn = randomInteger(2, 4);
          } else if (random <= 0.9) {
            //4-6
            rn = randomInteger(4, 6);
          } else {
            //6-11
            rn = randomInteger(6, 11);
          }
        }
        for (let i = 0; i < rn; i++) {
          add();
          await sleep(randomInteger(110, 300));
        }
        DoCount();
      }, randomTime);
    }
    DoCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Text
      textTransform={"uppercase"}
      textAlign="center"
      fontSize={"3xl"}
      color={"red"}
      fontWeight="bold"
    >
      {count} / {collectionSize}
    </Text>
  );
};

export default Counter;
