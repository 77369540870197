import { parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";

export type MintRead = {
  collectionSize: number;
  price: BigNumber;
  strikePrice: BigNumber;
  maxPerMint: number;
  saleActive?: boolean;
  soldOut: boolean;
};

const price = parseUnits("0.085");
const strikePrice = parseUnits("0.1");
const maxPerMint = 10;
const collectionSize = 9999;

const useMintRead = (): MintRead => {
  return {
    collectionSize,
    price,
    strikePrice,
    saleActive: false,
    maxPerMint,
    soldOut: false,
  };
};

export default useMintRead;
