import { BigNumber, ContractTransaction } from "ethers";
import { trackEvent } from "lib/events";
import { useCallback, useState } from "react";
import { useContract, useSigner } from "wagmi";
import * as Sentry from "@sentry/nextjs";

import ABI from "../contract/abi.json";
import { formatEther, parseEther, parseUnits } from "@ethersproject/units";

type ContractRevertError =
  | "MintSaleNotStarted"
  | "MintExceedsMintLimit"
  | "MintExceedsMaxSupply"
  | "MintInsufficientPayment"
  | "PresaleNotAlphalist";

const contractErrors: { [key: string]: string } = {
  Unknown: "Oops.. Something went wrong. Please retry.",
  MintSaleNotStarted: "The sale has not started yet.",
  MintExceedsMintLimit: "Mint limit exceeded.",
  MintExceedsMaxSupply: "Max supply reached.",
  MintInsufficientPayment: "You send less ETH as required.",
  "insufficient funds":
    "You have insufficient funds. You must have at least 0.085 ETH in your wallet.",
  "denied transaction":
    "Please approve the transaction in your wallet to mint.",
};

type RPCError = {
  code: Number;
  message: string;
};

type ContractError = {
  code: number;
  message: string;
  data: {
    code?: number;
    message?: string;
    originalError?: {
      jsonrpc: string;
      id: number;
      error: RPCError;
    };
  };
};

const getTransactionErrorKey = (
  e: unknown
): { key: string; message: string } => {
  if (isContractError(e)) {
    for (const key in contractErrors) {
      if (e.data.originalError) {
        if (e.data.originalError.error.message.includes(key)) {
          return { key, message: e.data.originalError.error.message };
        }
      }
      if (e.data.message && e.data.message.includes(key)) {
        return { key, message: e.data.message };
      }
      if (e.message.includes(key)) {
        return { key, message: e.message };
      }
    }
  }
  if (isRPCError(e)) {
    for (const key in contractErrors) {
      if (e.message.includes(key)) {
        return { key, message: e.message };
      }
    }
  }
  if (typeof e === "string") {
    for (const key in contractErrors) {
      if (e.includes(key)) {
        return { key, message: e };
      }
    }
  }
  return { key: "Unknown", message: "Unknown" };
};

const regexWant = /want (\d+)/gm;

const getTransactionError = (e: unknown): string => {
  const { key, message } = getTransactionErrorKey(e);
  if (key === "insufficient funds") {
    const wantMatch = message.match(regexWant);
    if ((wantMatch?.length ?? 0) > 0) {
      const want = parseUnits(wantMatch![0].substring(5), "wei");
      return `You have insufficient funds. You must have at least Ξ${formatEther(
        want.add(parseEther("0.005"))
      )} in your wallet.`;
    }
  }
  return contractErrors[key];
};

const isRPCError = (err: unknown): err is RPCError =>
  (err as RPCError).code !== undefined &&
  (err as RPCError).message !== undefined;

const isContractError = (err: unknown): err is ContractError =>
  (err as ContractError).code !== undefined &&
  (err as ContractError).message !== undefined &&
  (err as ContractError).data !== undefined;

export enum MintState {
  Initial,
  Loading,
  Success,
  Error,
}

type MintParams = {
  price: BigNumber;
  amount: number;
  isPresale: boolean;
  proof?: string[];
  address: string;
  chainId: number;
};

const useMintWrite = () => {
  const [
    { data: signer, error: signerErr, loading: signerLoading },
    getSigner,
  ] = useSigner();

  const contractConfig = {
    addressOrName: process.env.NEXT_PUBLIC_CONTRACT_ADDRESS!,
    contractInterface: ABI,
  };

  const contract = useContract({
    ...contractConfig,
    signerOrProvider: signer,
  });

  const [mintState, setMintState] = useState<MintState>(MintState.Initial);
  const [mintTx, setMintTx] = useState<ContractTransaction | undefined>(
    undefined
  );
  const [lastError, setLastError] = useState<string | undefined>();

  const onMint = useCallback(
    async ({ price, amount, address, chainId }: MintParams) => {
      try {
        setMintTx(undefined);
        setLastError(undefined);
        setMintState(MintState.Loading);

        const tx = await contract.mint(amount, {
          value: price.mul(amount),
        });

        setMintTx(tx);
        await tx.wait();

        trackEvent("mint_completed", {
          sale: "public",
          account: address,
          amount: amount,
          chainId,
          usd: amount * 250,
        });
        setMintState(MintState.Success);
      } catch (e) {
        trackEvent("mint_failed", {
          sale: "public",
          account: address,
          amount: amount,
          reason: "transaction_error",
          chainId,
          usd: amount * 250,
        });
        console.error(e);
        const transErr = getTransactionError(e);
        setLastError(getTransactionError(e));
        setMintState(MintState.Error);
        if (
          !transErr.includes("insufficient funds") &&
          !transErr.includes("approve the transaction")
        ) {
          Sentry.captureException(e);
        }
      }
    },
    [contract]
  );

  return {
    state: mintState,
    tx: mintTx,
    error: lastError,
    mint: onMint,
  };
};

export default useMintWrite;
